import React, { useEffect, useRef } from 'react';
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import TopNav from 'components/Navbars/TopNav-7';
import Navbar from 'components/Navbars/ITCreativeNav';
import Features from 'components/Saas/Features';
import Footer from 'components/Saas/Footer';
import SideMenu from 'components/ITCreative/SideMenu';

const Loans = () => {
  const navbarRef = useRef(null);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  return (
    <MainLayout>
      {/* <TopNav style="5" /> */}
      <SideMenu />
      <Navbar navbarRef={navbarRef} />
      <main className="services-page style-5">
        <Features isServices />        
      </main>
      <Footer noWave />
    </MainLayout>
  )
}

export const Head = () => {
  return (
    <>
      <title>Mutual Trust Loan</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  )
}

export default Loans;